import React from 'react';
import PropTypes from 'prop-types';
import isDomainExternal from '../../../infrastructure/utilities/network/isDomainExternal';
import { withErrorBoundary } from '../../containers/HOC/ErrorBoundary';

const AnchorLink = (props) =>
{
    if (!props.href && !props.children)
    {
        throw new Error('Both href and children were not provided to the anchor element. This is invalid usage.');
    }
    if (!props.href)
    {
        const err = new Error('No href Provided. This is an invalid usage of the anchor element.');
        err.info = props.children;
        throw err;
    }
    if (!props.children)
    {
        const err = new Error('No children provided. This is an invalid usage of the anchor element.');
        err.info = props.href;
        throw err;
    }

    return (
        <a href={props.href}
            style={props.style}
            title={props.title}
            className={props.className}
            target={props.target}
            rel={isDomainExternal(props.href) && props.target === '_blank' ? 'noopener noreferrer' : null}
        >
            {props.children}
        </a>
    );
};

AnchorLink.propTypes = {
    href: PropTypes.string.isRequired,
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.element,
    ]).isRequired,
    style: PropTypes.object,
    title: PropTypes.string,
    target: PropTypes.string,
};

export default withErrorBoundary(AnchorLink);
