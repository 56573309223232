
export default [
    '/adult-bibel.jpg',
    '/roadview.jpg',
    '/largelandscape.jpg',
    '/forest.jpg',
    '/tree.jpg',
    '/rolling-landscape.jpg',
    '/architecture.jpg',
    '/castel.jpg',
    '/landscape-1802340_640.jpg',
    '/florence-1655830_640.jpg',
    '/hamburg-3846525_640.jpg',
    '/mountain-1209497_640.jpg',
    '/bavaria-2316958_640.jpg',
];
