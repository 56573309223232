import React from 'react';

import { ErrorBoundary } from './index';

const withErrorBoundary = (OriginalComponent, backdropJsx) =>
{
    return class WrappedComponent extends React.Component
    {
        render()
        {
            return (
                <ErrorBoundary backdropJsx={backdropJsx || null}>
                    <OriginalComponent {...this.props} />
                </ErrorBoundary>
            );
        }
    };
};

export default withErrorBoundary;
