import React from 'react';
import PropTypes from 'prop-types';
import { appLogger } from '../../../../infrastructure/utilities/applogger';

class ErrorBoundary extends React.Component
{
    static getDerivedStateFromError()
    {
        return {
            hasError: true,
        };
    }

    constructor(props)
    {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    componentDidCatch(error, info)
    {
        appLogger.captureError({
            error,
            info,
        });
    }

    render()
    {
        const backdropContent = this.props.backdropJsx || null;
        if (this.state.hasError)
        {
            return backdropContent;
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node,
    backdropJsx: PropTypes.node,
};

export default ErrorBoundary;
