
import * as SentryInstance from '@sentry/browser';
import parameters from '../../../application/_config/parameters';

SentryInstance.init({
    dsn: 'https://909fd0bf9c4b4a08b9dc9cc55012f174@sentry.io/1337174',
    environment: parameters.environment,
});

const appLogger = {
    // All logs here will show up in fitfox-js-logs
    captureLog: (config) =>
    {
        try
        {
            let err;
            if (!config)
            {
                err = new Error('No log was sent, because no logging data was provided.');
            }
            if (typeof config !== 'object')
            {
                err = new Error('No log was sent, because config is an incorrect data type.');
            }
            if (config.title && typeof config.title !== 'string')
            {
                err = new Error('No log was sent, because config[title] is incorrect data type ' + typeof config.title);
            }
            if (config.level && typeof config.level !== 'string')
            {
                err = new Error('No log was sent, because config[level] is incorrect data type ' + typeof config.level);
            }
            if (config.message && typeof config.message !== 'string')
            {
                err = new Error('No log was sent, because config[message] is incorrect data type ' + typeof config.message);
            }
            if (config.tags && typeof config.tags !== 'object')
            {
                err = new Error('No log was sent, because config[tags] is incorrect data type ' + typeof config.tags);
            }

            if (err)
            {
                return SentryInstance.captureException(err);
            }

            return SentryInstance.captureMessage(
                config.title || 'Unspecified log title',
                {
                    level: config.level || 'info',
                    extra: {
                        message: config.message,
                    },
                    tags: config.tags || null,
                },
            );
        }
        catch (e)
        {
            return SentryInstance.captureException(e);
        }
    },
    // All logs here will show ups in fitfox-js
    captureError: (config) =>
    {
        try
        {
            let err;
            if (!config.error)
            {
                err = new Error('No error log was sent, because no logging data was provided.');
            }
            if (config.extra && typeof config.extra !== 'object')
            {
                err = new Error('No log was sent, because config[extra] is incorrect data type ' + typeof config.extra);
            }

            if (err)
            {
                return SentryInstance.captureException(err);
            }

            return SentryInstance.captureException(
                config.error,
                {
                    extra: config.extra,
                },
            );
        }
        catch (e)
        {
            return SentryInstance.captureException(e);
        }
    },
};

export {
    appLogger,
    SentryInstance,
};

