import React from 'react';
import PropTypes from 'prop-types';

import Tag from '../../../atoms/Tags/Tag';

class TagsWrapper extends React.Component
{
    constructor(props)
    {
        super(props);
        this.tagNumberLimit = 10;
    }

    determineTagTitle({ locale, titleEn, titleDe })
    {
        let title = titleDe;
        if (locale === 'en')
        {
            title = titleEn;
        }

        return title;
    }

    buildTagsJsx(tags)
    {
        const { locale } = this.props;

        return tags.map((tag, index) => (
            <Tag
                slug={tag.slug} key={index}
                title={this.determineTagTitle({
                    locale: this.props.locale,
                    titleEn: tag.titleEn,
                    titleDe: tag.titleDe,
                })}
                locale={locale}
            />
        ),
        ).slice(0, this.tagNumberLimit);
    }

    renderTagLabel()
    {
        const { label } = this.props;

        if (label)
        {
            return (
                <div style={{ fontWeight: 'bold' }}>{label}</div>
            );
        }

        return null;
    }

    render()
    {
        if (this.props.tags)
        {
            return (
                <div style={this.props.style || {}}>
                    {this.renderTagLabel()}
                    <div style={{ marginTop: '1.3rem' }}>
                        {this.buildTagsJsx(this.props.tags)}
                    </div>
                </div>
            );
        }

        return null;
    }
}

TagsWrapper.propTypes = {
    tags: PropTypes.array,
    locale: PropTypes.string,
    label: PropTypes.string,
    style: PropTypes.object,
};

export default TagsWrapper;
