export const deriveAlternateLangUrlFromLocale = ({
    pathname,
    search,
    locale,
}) =>
{
    if (locale === 'en')
    {
        const newPathName = pathname.replace('/en', '') + search;

        return newPathName;
    }

    if (locale === 'de')
    {
        const newPathName = '/en' + pathname + search;

        return newPathName;
    }

    return pathname + search;
};

export const buildUrlWithLocaleAndPath = (locale, path) =>
{
    if (!locale)
    {
        throw new Error('No Locale Provided to `buildUrlWithLocaleAndPath`');
    }
    if (!path)
    {
        throw new Error('No Url Path Provided to `buildUrlWithLocaleAndPath`');
    }
    if (locale === 'en')
    {
        if (path[0] !== '/')
        {
            return '/en/' + path;
        }

        return '/en' + path;
    }
    if (locale === 'de')
    {
        if (path[0] !== '/')
        {
            return '/' + path;
        }

        return path;
    }
    throw new Error('Invalid path' + path + ' provided');
};
