import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Chip from '@mui/material/Chip';
import { buildUrlWithLocaleAndPath } from '../../../../infrastructure/utilities/deriveUrlFromLocale';

class Tag extends React.Component
{
    render()
    {
        const { slug, locale } = this.props;

        return(
            <Chip
                variant="outlined"
                style={{
                    margin: '.2rem',
                    height: '1.9rem',
                    color: 'black',
                    background: '#e9e9e9',
                    border: 'none',
                    borderRadius: '5px',
                }}
                label={
                    <Link
                        style={{ textDecoration: 'none', color: 'black' }}
                        to={buildUrlWithLocaleAndPath(locale, `/publikationen/?tag=${slug}`)}>
                        {this.props.title}
                    </Link>}
            />
        );
    }
}

Tag.propTypes = {
    title: PropTypes.string,
    slug: PropTypes.string,
    locale: PropTypes.string,
};

export default Tag;
