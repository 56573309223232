const isSubDomainInternal = href => (href.split('.').length >= 3 && href.split('.')[1] === 'glaubensfutter' && href.split('.')[2].substring(0, 2) === 'de');
const isInternalAnchorLink = href => (href.length && href[0] === '#');
const isSecondLevelDomainInternal = href => ((href.split('.')[0] === 'https://glaubensfutter' || href.split('.')[0] === 'http://glaubensfutter' || href.split('.')[0] === 'glaubensfutter' ) && href.split('.')[1].substring(0, 2) === 'de');

const isDomainExternal = href =>
{
    if (!href)
    {
        return true;
    }
    let _external = true;

    if (isSubDomainInternal(href) || isSecondLevelDomainInternal(href) || isInternalAnchorLink(href))
    {
        _external = false;
    }

    return _external;
};

export default isDomainExternal;
